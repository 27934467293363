import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { JsonTableViewRowDataType, getDataTypeNected } from 'ui';

import { TableDetailType } from './JsonInTableView';

export const getDetailsForTableView = (
  value: any,
  dataType: string,
  parentKey = ''
) => {
  const tableDetails: TableDetailType = {
    header: [],
    row: [],
  };

  if (dataType === 'json') {
    const currentRow: JsonTableViewRowDataType = {};

    Object.keys(value).forEach((key) => {
      tableDetails.header.push({
        name: key,
        dataType: getDataTypeNected(value[key]),
      });

      currentRow[key] = value[key];
    });

    tableDetails.row.push(currentRow);
  } else if (dataType === 'list') {
    if (!_isNil(value) && !_isEmpty(value)) {
      const firstValueDatatype = getDataTypeNected(value[0]);

      const listTableDetails: TableDetailType[] = value.map((obj: any) =>
        getDetailsForTableView(obj, firstValueDatatype, parentKey)
      );

      tableDetails.header = listTableDetails?.[0].header;

      listTableDetails.forEach((currentdDetail) => {
        tableDetails.row.push(currentdDetail.row?.[0] ?? {});
      });
    }
  } else {
    tableDetails.header = [
      {
        name: parentKey,
        dataType,
      },
    ];

    tableDetails.row.push({
      [parentKey]: value,
    });
  }

  return tableDetails;
};
