import styled from 'styled-components';

import { Typography } from '../Typography';

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;
export const StyledTh = styled.th`
  background-color: var(--color-white);
  border: 1px solid var(--color-lightGray8);
  padding: 0.5rem;

  .name-text-style {
    text-align: left;
  }
`;

export const StyledTr = styled.tr`
  background-color: var(--color-white);
`;

export const StyledTd = styled.td`
  border: 1px solid var(--color-lightGray8);
  padding: 0.5rem;
  word-break: break-word;
  text-align: left;
  vertical-align: top;
`;

export const LinkText = styled(Typography)`
  text-decoration: underline;
  color: var(--color-primary1);
  cursor: pointer;
`;

export const TypeText = styled(Typography)`
  text-transform: capitalize;
`;
