import { Inline } from '@bedrock-layout/inline';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

import { IconByDataType } from '../ConditionPopovers/RulePopover/IconByDataType';
import { Typography } from '../Typography';
import {
  LinkText,
  StyledTable,
  StyledTd,
  StyledTh,
  StyledTr,
  TypeText,
} from './JsonTableViewer.styled';

export type JsonTableViewHeaderDataType = {
  name: string;
  dataType?: string;
};

export type JsonTableViewRowDataType = Record<string, any>;

export type JsonTableViewerProps = {
  header: JsonTableViewHeaderDataType[];
  row: JsonTableViewRowDataType[];
  onClick: (key: string, index: number, dataType?: string) => void;
};

export function JsonTableViewer({
  header,
  row,
  onClick,
}: JsonTableViewerProps) {
  if (_isNil(header) || _isEmpty(header)) {
    return (
      <Inline justify="center" style={{ marginTop: '1rem' }}>
        <Typography>No Data</Typography>
      </Inline>
    );
  }

  return (
    <StyledTable>
      <thead>
        <tr>
          {header.map((obj) => (
            <StyledTh key={obj.name}>
              <Inline align="end" gutter={'1.6rem'}>
                <Inline align="center" gutter={'0.8rem'}>
                  <span>
                    <IconByDataType
                      dataType={obj.dataType ?? 'string'}
                      color="var(--color-paleDodgerBlue)"
                    />
                  </span>
                  <Typography className="name-text-style" fontWeight={700}>
                    {obj.name}
                  </Typography>
                </Inline>
                <TypeText name="paragraphSmall">
                  {obj.dataType ?? 'string'}
                </TypeText>
              </Inline>
            </StyledTh>
          ))}
        </tr>
      </thead>
      <tbody>
        {row.map((obj, index) => (
          <StyledTr key={index}>
            {header.map((headerObj) => {
              const { name, dataType = 'string' } = headerObj;

              if (dataType === 'json') {
                return (
                  <StyledTd
                    key={name}
                    onClick={() => onClick(name, index, dataType)}
                  >
                    <LinkText>{`Json {}`}</LinkText>
                  </StyledTd>
                );
              }

              if (dataType === 'list') {
                return (
                  <StyledTd
                    key={name}
                    onClick={() => onClick(name, index, dataType)}
                  >
                    <LinkText>{`List []`}</LinkText>
                  </StyledTd>
                );
              }

              return <StyledTd key={name}>{`${obj[name] as string}`}</StyledTd>;
            })}
          </StyledTr>
        ))}
      </tbody>
    </StyledTable>
  );
}
