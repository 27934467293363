import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { GoArrowLeft } from 'react-icons/go';
import {
  IconButton,
  JsonTableViewHeaderDataType,
  JsonTableViewRowDataType,
  JsonTableViewer,
  Typography,
  getDataTypeNected,
} from 'ui';
import { IconByDataType } from 'ui/src/ConditionPopovers/RulePopover/IconByDataType';

import { getPropertyIfExists } from '../../utils/common';
import {
  BreadCrumbContainer,
  BreadCrumbItem,
  ItemStyle,
  ListContainer,
  OutputHeaderContainer,
} from './JsonInTableView.styled';
import { getDetailsForTableView } from './helperFunction';

type JsonInTableViewProps = {
  json: any;
};

type BreadCrumInfoType = {
  name: string;
  dataType: string;
  key: string;
};

export type TableDetailType = {
  header: JsonTableViewHeaderDataType[];
  row: JsonTableViewRowDataType[];
};

export const JsonInTableView = ({ json }: JsonInTableViewProps) => {
  const [breadcrumList, setBreadCrumList] = useState<BreadCrumInfoType[]>([]);
  const [jsonData, setJsonData] = useState<any>({});

  const [tableDetails, setTableDetails] = useState<TableDetailType>();

  useEffect(() => {
    setBreadCrumList([
      {
        name: 'Root',
        dataType: getDataTypeNected(jsonData),
        key: 'root',
      },
    ]);
  }, [JSON.stringify(jsonData)]);

  useEffect(() => {
    setJsonData({
      root: json,
    });
  }, [JSON.stringify(json)]);

  useEffect(() => {
    if (!_isNil(breadcrumList) && !_isEmpty(breadcrumList)) {
      const latestBreadCrumInfo = breadcrumList[breadcrumList.length - 1];
      const value = getPropertyIfExists(jsonData, latestBreadCrumInfo.key);

      const details = getDetailsForTableView(
        value,
        latestBreadCrumInfo.dataType,
        latestBreadCrumInfo.key.substring(
          latestBreadCrumInfo.key.lastIndexOf('.') + 1
        )
      );
      setTableDetails(details);
    } else {
      setTableDetails(undefined);
    }
  }, [JSON.stringify(breadcrumList)]);

  const handleCellClick = (key: string, index: number, dataType = 'string') => {
    if (!_isNil(breadcrumList) && !_isEmpty(breadcrumList)) {
      const lastBreadCrumInfo = breadcrumList[breadcrumList.length - 1];
      let currentKey = lastBreadCrumInfo.key;

      if (lastBreadCrumInfo.dataType === 'list') {
        currentKey += `[${index}].${key}`;
      } else {
        currentKey += `.${key}`;
      }

      setBreadCrumList([
        ...breadcrumList,
        {
          name: key,
          dataType,
          key: currentKey,
        },
      ]);
    }
  };

  const handleBack = () => {
    setBreadCrumList(breadcrumList.slice(0, breadcrumList.length - 1));
  };

  const handleBreadCrumbClick = (key: string) => {
    const index = breadcrumList.findIndex((item) => item.key === key);

    if (index !== -1) {
      setBreadCrumList(breadcrumList.slice(0, index + 1));
    }
  };

  if (_isNil(breadcrumList) || _isEmpty(breadcrumList)) {
    return null;
  }

  const latestBreadCrumInfo = breadcrumList[breadcrumList.length - 1];

  return (
    <Stack gutter={0} style={{ minWidth: 'fit-content' }}>
      <BreadCrumbContainer align="center" gutter="2rem">
        <IconButton disabled={breadcrumList.length === 1} onClick={handleBack}>
          <GoArrowLeft
            size={20}
            color={
              breadcrumList.length === 1
                ? 'var(--color-darkGray)'
                : 'var(--color-primary1)'
            }
          />
        </IconButton>
        <ListContainer align="center" gutter="1rem">
          {breadcrumList.map((currentBreadcrumb, index) => (
            <Inline key={currentBreadcrumb.key} align="center" gutter="1rem">
              <BreadCrumbItem
                onClick={() => handleBreadCrumbClick(currentBreadcrumb.key)}
              >
                <IconByDataType
                  dataType={currentBreadcrumb.dataType}
                  color="var(--color-paleDodgerBlue)"
                />
                <ItemStyle>{currentBreadcrumb.name}</ItemStyle>
              </BreadCrumbItem>

              {index !== breadcrumList.length - 1 && (
                <FiChevronRight size={24} />
              )}
            </Inline>
          ))}
        </ListContainer>
      </BreadCrumbContainer>

      <OutputHeaderContainer
        align="center"
        gutter={'1.5rem'}
        $hasBottomBorder={
          _isNil(tableDetails?.header) || _isEmpty(tableDetails?.header)
        }
      >
        <Inline align="center" gutter={'0.5rem'}>
          <span>
            <IconByDataType
              dataType={latestBreadCrumInfo.dataType ?? 'string'}
              color="var(--color-paleDodgerBlue)"
            />
          </span>
          <Typography fontWeight={700}>{latestBreadCrumInfo.name}</Typography>
        </Inline>
        <Typography className="dataTypetype-style" name="paragraphSmall">
          {latestBreadCrumInfo.dataType ?? 'string'}
        </Typography>
      </OutputHeaderContainer>

      <div>
        <JsonTableViewer
          header={tableDetails?.header ?? []}
          row={tableDetails?.row ?? []}
          onClick={handleCellClick}
        />
      </div>
    </Stack>
  );
};
